import {
  TICKET_CREATE_FAIL,
  TICKET_CREATE_REQUEST,
  TICKET_CREATE_RESET,
  TICKET_CREATE_SUCCESS,
  TICKET_DETAILS_FAIL,
  TICKET_DETAILS_REQUEST,
  TICKET_DETAILS_SUCCESS,
  TICKET_LIST_MY_FAIL,
  TICKET_LIST_MY_REQUEST,
  TICKET_LIST_MY_SUCCESS,
} from '../constants';

export const ticketCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_CREATE_REQUEST:
      console.log('TICKET CREATED')
      return { loading: true };
    case TICKET_CREATE_SUCCESS:
      const { ticket, status, statusText } = action.payload;
      return {
        loading: false,
        ticket,
        status,
        statusText,
      };
    case TICKET_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TICKET_CREATE_RESET:
      return (state = {});

    default:
      return state;
  }
};

export const ticketDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case TICKET_DETAILS_SUCCESS:
      return {
        loading: false,
        ticket: action.payload,
      };
    case TICKET_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const myTicketListReducer = (state = {}, action) => {
  // // console.log('from reducer', action.payload);
  switch (action.type) {
    case TICKET_LIST_MY_REQUEST:
      return { loading: true };
    case TICKET_LIST_MY_SUCCESS:
      return { loading: false, tickets: action.payload };
    case TICKET_LIST_MY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// export const ticketResetReducer = (state = {}, action) => {
//   switch (action.type) {
//     case TICKET_RESET_REQUEST:
//       return state;

//     default:
//       return state;
//   }
// };
