import React from 'react';
import { useSelector } from 'react-redux';

import Logo from '../assets/images/Suncoast-Built-logo-white.png';

import { Navbar } from './Navbar';
import { UserMenu } from './UserMenu';

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    marginRight: 2,
  },
  titleName: {
    fontSize: '22px',
    marginLeft: '10px',
  },
  titleUrl: {
    fontSize: '15px',
  },
  boxStyle: {
    marginLeft: 'auto',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const appEnv = process.env.REACT_APP_ENV;

const Header = () => {
  const classes = useStyles();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let homeUrl = window.location.origin;

  const displayName = userInfo ? userInfo.bc_customer_displayName : '';
  const customerTier = userInfo ? userInfo.bc_customer_tierName : '';

  return (
    <AppBar position='sticky' className={classes.appBar} color='primary'>
      <Toolbar>
        <Link href={homeUrl}>
          <Box id='logoBox' display={{ xs: 'none', sm: 'block' }}>
            <img
              src={Logo}
              height='35'
              width='365'
              edge='end'
              className={classes.logo}
              alt='logo'
            />
          </Box>
        </Link>
        <Typography className={classes.title}>
          <span className={classes.titleName}>
            {appEnv !== 'prod' && `(${appEnv})`}
          </span>
        </Typography>
        <Typography className={classes.title}>
          {`${displayName} ${customerTier} Portal`}
        </Typography>
        <Box className={classes.boxStyle}>
          {userInfo && userInfo.isApproved && <Navbar userInfo={userInfo} />}
          {userInfo && <UserMenu userInfo={userInfo} />}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { Header };
