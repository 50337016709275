import React, { useEffect, useState, useContext } from 'react';
import Fuse from 'fuse.js';
import { toast } from 'react-toastify';

import { StoreContext } from '../context';
import '../assets/css/store.css';
import { Message, Loader } from '../components';

import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  collapsedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
  expandIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: 'pointer',
  },
}));

const StoreParts2 = ({ classes }) => {
  const classes2 = useStyles();

  const ctx = useContext(StoreContext);
  const {
    portalItemsLoading,
    portalItemsError,
    portalParts,
    portalCategories,
    bcParts,
    handleAddPrtToCart,
  } = ctx;


  // console.log('portalCategories', portalCategories);

  const [portalPartsNameFilter, setPortalPartsNameFilter] = useState('');
  const [portalPartsCategoryFilter, setPortalPartsCategoryFilter] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [partQuantities, setPartQuantities] = useState({});

  useEffect(() => {});

  // const twoby8s = portalParts?.filter((part) => {
  //   return part?.displayName2?.toLowerCase() === '2x8';
  // });

  // console.log('twoby8s', twoby8s);

  // let portalCats = portalCategories?.filter((pc) => {
  //   return !pc.toLowerCase().includes('PK-');
  // });

  let portalPartsFiltered;
  if (portalParts && Array.isArray(portalParts)) {
    let portalPartsIn = portalParts;
    portalPartsIn = portalPartsIn.filter((part) => part?.displayName2 !== '');
    if (portalPartsNameFilter || portalPartsCategoryFilter) {
      if (portalPartsNameFilter) {
        const fuse = new Fuse(portalParts, {
          keys: ['displayName2'],
          shouldSort: true,
          includeMatches: true,
          threshold: 0.3,
          isCaseSensitive: false,
          ignoreLocation: true,
        });
        let result = fuse.search(portalPartsNameFilter);
        portalPartsIn = result.map((result) => result.item);
      }
      if (portalPartsCategoryFilter) {
        portalPartsIn = portalPartsIn.filter(
          (part) => part?.itemCategoryCode === portalPartsCategoryFilter
        );
      }
    }
    portalPartsIn.sort((a, b) => a.displayName2.localeCompare(b.displayName2));
    portalPartsFiltered = portalPartsIn;
  } else {
    portalPartsFiltered = portalParts;
  }
  const handleAttributeChange = (displayName2, attributeName, value) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [displayName2]: {
        ...(prevAttributes[displayName2] || {}),
        [attributeName]: value,
      },
    }));
  };

  const handleQuantityChange = (displayName2, quantity) => {
    setPartQuantities((prevQuantities) => ({
      ...prevQuantities,
      [displayName2]: quantity,
    }));
  };

  const findRelevantPart = (displayName2) => {
    const attributes = selectedAttributes[displayName2] ? selectedAttributes[displayName2]: {};
    return bcParts.find((bcPart) => {
      if (bcPart.displayName2 !== displayName2) return false;

      return Object.entries(attributes).every(([attributeName, value]) => {
        return bcPart.attributes.some(
          (attr) =>
            attr.attributeName === attributeName &&
            attr.attributeValue === value
        );
      });
    });
  };

  const addToCartHandler = (displayName2) => {
    const quantity = partQuantities[displayName2];
    if (!quantity || quantity === 0) {
      toast.error('Please enter a quantity');
      return;
    }
    const relevantPart = findRelevantPart(displayName2);
    
    if (relevantPart) {
      handleAddPrtToCart(
        relevantPart._id,
        relevantPart.number,
        relevantPart.displayName,
        quantity,
        relevantPart.prices
      );
      setSelectedAttributes({});
      setPartQuantities({});
    } else {
      toast.error('Please select all attributes');
      return;
    }
  };


  return portalItemsLoading ? (
    <Loader />
  ) : portalItemsError ? (
    <Message severity='error'>{portalItemsError}</Message>
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingLeft: 15 }}>
        <Grid container spacing={2} wrap='wrap' alignItems='center'>
          <Grid container item xs={12} direction='row'>
            <Grid item xs={3}>
              <TextField
                label='Filter by Part Name'
                value={portalPartsNameFilter}
                onChange={(e) => setPortalPartsNameFilter(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontVariant: 'normal',
                    color: 'rgba(0, 0, 0, 0.75)',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {portalPartsNameFilter && (
                        <IconButton
                          aria-label='clear filter'
                          edge='end'
                          onClick={() => setPortalPartsNameFilter('')}
                          size='small'
                        >
                          <Clear fontSize='small' color='error' />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: 10, marginTop: -15 }}
                size='small'
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label='Filter by Category'
                value={portalPartsCategoryFilter}
                onChange={(e) => setPortalPartsCategoryFilter(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontVariant: 'normal',
                    color: 'rgba(0, 0, 0, 0.75)',
                  },
                }}
                style={{ marginBottom: 10, marginTop: -15, marginLeft: 10 }}
                size='small'
                select
                fullWidth
              >
                <MenuItem value=''>All</MenuItem>
                {portalCategories &&
                  portalCategories?.map((pc, i) => (
                    <MenuItem key={i} value={pc}>
                      {pc}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={4} className={classes2.header}>
            Part Name
          </Grid>
          <Grid item xs={5} className={classes2.header}>
            Attributes
          </Grid>
          <Grid
            item
            container
            xs={1}
            alignItems='center'
            justifyContent='center'
            className={classes2.header}
          >
            Quantity
          </Grid>
          <Grid
            item
            container
            xs={3}
            alignItems='center'
            justifyContent='center'
            className={classes2.header}
          ></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: 15,
        }}
      >
        {portalPartsFiltered &&
          portalPartsFiltered.map((part) => (
            <Grid
              container
              spacing={1}
              key={part?._id}
              wrap='wrap'
              alignItems='flex-end'
              className="partsAttributes"
            >
              <Grid item xs={4}>
                <Typography variant='body2'>{part?.displayName2}</Typography>
              </Grid>
              <Grid item container xs={5} spacing={2} direction='row'>
                {part &&
                  part?.attributes
                    ?.filter(
                      (attribute) => attribute?.attributeName !== 'Portal Kit'
                    ) 
                    ?.filter(
                      (attribute) => attribute?.attributeName !== 'Roof Kit'
                    )
                    ?.filter(
                      (attribute) => attribute?.attributeValues?.length > 1
                    )
                    .map((attribute) => (
                      
                      <Grid item xs={4} key={attribute?.attributeName}>
                        <TextField
                          select
                          label={attribute?.attributeName}
                          InputLabelProps={{
                            style: {},
                          }}
                          value={
                            selectedAttributes?.[part.displayName2]?.[
                              attribute.attributeName
                            ] || ''
                          }
                          fullWidth
                          size='small'
                          onChange={(e) =>
                            handleAttributeChange(
                              part?.displayName2,
                              attribute?.attributeName,
                              e.target.value
                            )
                          }
                        >
                          {attribute &&
                            attribute?.attributeValues?.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )
                    )}
              </Grid>
              <Grid
                item
                container
                xs={3}
                justifyContent='flex-end'
                alignItems='center'
                direction='row'
                spacing={1}
              >
                <Grid item>
                  <TextField
                    id={'outlined-number' + part?._id}
                    type='number'
                    // variant='outlined'
                    size='small'
                    required
                    inputProps={{ min: 0 }}
                    style={{ width: '4rem', textAlign: 'right' }}
                    value={partQuantities[part.displayName2] || 0}
                    onChange={(e) =>
                      handleQuantityChange(part.displayName2, e.target.value)
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => addToCartHandler(part.displayName2)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export { StoreParts2 };