import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import Fuse from 'fuse.js';
import { WarrantyDialog } from './WarrantyDialog';
import { adminListAllWarranties } from '../actions';
import { numberFormat } from '../utils/numberFormatter';

import { Message, Loader, TrackingDialog } from '.';

import {
  Link,
  makeStyles,
  TextField,
  Toolbar,
  Card,
  CardContent,
  TablePagination,
  Grid,
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  SvgIcon,
  withStyles,
  InputAdornment,
  IconButton,
  Box,
  Tooltip,
} from '@material-ui/core';
import {
  ArrowUpward,
  ArrowDownward,
  Language,
  Clear,
  Person,
  FiberManualRecord,
  LocalShipping,
  AssignmentReturned,
  AssignmentTurnedIn,
  // HighlightOff,
} from '@material-ui/icons';
import { ReactComponent as CASvg } from '../assets/images/ca.svg';
import { ReactComponent as USSvg } from '../assets/images/us.svg';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
  expandIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: 'pointer',
  },
}));



const CardedWarrantyList = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const adminListWarranties = useSelector((state) => state.adminListWarranties);
  const { warranties: adminWarranties, loading } = adminListWarranties;
  
  const [sortedField, setSortedField] = useState('updatedAt');
  const [count, setCount] = useState(0);
  const [sortDirection, setSortDirection] = useState('desc');
  const [page, setPage] = useState(0);
  const updateWarranties = (offset) => {
    dispatch(adminListAllWarranties(offset));
}

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
    if (userInfo?.isAdmin) {
      updateWarranties(0)
    }
  }, [userInfo, dispatch]);


  const sortWarranties = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event, newPage) => {
    let offset = 50 * newPage
    updateWarranties(offset)
    setPage(newPage);
  };
  const warranties = adminWarranties?.warranties ? adminWarranties?.warranties.sort((a, b) => {
    let result;
    if (a[sortedField] > b[sortedField]) result = 1;
    if (a[sortedField] < b[sortedField]) result = -1;
    if (sortDirection === 'desc') result *= -1;
    return result;
  }) : [];

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <>
    <div>
      <Card style={{ marginBottom: 5 }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'createdAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('createdAt')}
            >
              Created
              {sortedField === 'createdAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'updatedAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('updatedAt')}
            >
              Updated
              {sortedField === 'updatedAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'itemsCount'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('itemsCount')}
              // style={{ paddingLeft: 10 }}
            >
              Number of Items
              {sortedField === 'itemsCount' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'userDetails.bc_customer_displayName'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('userDetails.bc_customer_displayName')}
              // style={{ paddingLeft: 10 }}
            >
              Dealer Name
              {sortedField === 'userDetails.bc_customer_displayName' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'userDetails.userName'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('userDetails.userName')}
              // style={{ paddingLeft: 10 }}
            >
              Portal User
              {sortedField === 'userDetails.userName' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              className={
                sortedField === 'warrantyStatus'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortWarranties('warrantyStatus')}
            >
              Status
              {sortedField === 'warrantyStatus' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
            >
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {warranties && warranties.length > 0 ? (
        warranties.map((warranty) => (
          <Card
            key={warranty._id}
            style={{ marginBottom: 5, cursor: 'pointer' }}
          >
            <CardContent>
              <Grid container direction='row' spacing={1}>
                <Grid item xs={12} sm={2}>
                  {format(new Date(warranty.createdAt), 'MMM dd, yyyy')}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {format(new Date(warranty.updatedAt), 'MMM dd, yyyy')}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {warranty.itemsCount}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {warranty.userDetails.userName}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {warranty.userDetails.bc_customer_displayName}
                </Grid>
                <Grid item xs={12} sm={1}>
                    {warranty.warrantyStatus}
                </Grid>
                <Grid item xs={12} sm={1}>
                  <WarrantyDialog mode='edit' order={warranty.order} warranty={warranty} />
                  <WarrantyDialog mode='view' order={warranty.order} warranty={warranty} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Card>
          <CardContent>
            <Typography variant='h6' align='center'>
              No Warranties to display.
            </Typography>
          </CardContent>
        </Card>
      )}
       <TablePagination
        component="div"
        count = {warranties.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={50}
        labelRowsPerPage=""
      />
    </div>
    </>
  );
};

const WarrantyListAdmin = () => {
  const classes = useStyles();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [history, userInfo]);

  return error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <CardedWarrantyList classes={classes}  />
  );
};

export { WarrantyListAdmin };
