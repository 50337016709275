import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import '../assets/css/account.css';
import { WarrantyDialog } from './WarrantyDialog';

import { listMyWarranties } from '../actions';
import { numberFormat } from '../utils/numberFormatter';

import { Message, Loader, DeleteDialog } from '../components';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Typography,
  Box,
  makeStyles,
  IconButton,
  TablePagination,
  Collapse
} from '@material-ui/core';
import {
  Pageview,
  Edit,
  ArrowUpward,
  ArrowDownward,
  KeyboardArrowDown,
  KeyboardArrowUp
  // GetApp
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
}));

const WarrantyListAccount = ({ type }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const warrantyListMy = useSelector((state) =>  state.warrantyListMy);
  const { warranties: myWarranties, loading } = warrantyListMy;

  const [sortedField, setSortedField] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [page, setPage] = useState(0);


  const updateWarranties = (offset) => {
      dispatch(listMyWarranties(offset));
    
  }

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
    updateWarranties(0)
  }, [history, userInfo, dispatch]);

  const sortWarranties = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortDirection('asc');
    }
  };
  const handleChangePage = (event, newPage) => {
    let offset = 20 * newPage
    updateWarranties(offset)
    setPage(newPage);
  };
  const warranties = myWarranties?.warranties ? myWarranties?.warranties.sort((a, b) => {
    // if (sortedField === null) return a.updatedAt > b.updatedAt ? -1 : 1;
    let result;
    if (a[sortedField] > b[sortedField]) result = 1;
    if (a[sortedField] < b[sortedField]) result = -1;
    if (sortDirection === 'desc') result *= -1;
    return result;
  }) : [];

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <Paper>
      <TableContainer >
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell
                align='left'
                className={
                  sortedField === 'createdAt'
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortWarranties('createdAt')}
              >
                Date Placed
                {sortedField === 'createdAt' &&
                  (sortDirection === 'asc' ? (
                    <ArrowUpward color='action' className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward color='action' className={classes.sortIcon} />
                  ))}
              </TableCell>
              <TableCell
                className={
                  sortedField === 'warrantyStatus'
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortWarranties('warrantyStatus')}
              >
                Warrant Status
                {sortedField === 'warrantyStatus' &&
                  (sortDirection === 'asc' ? (
                    <ArrowUpward color='action' className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward color='action' className={classes.sortIcon} />
                  ))}
              </TableCell>
              <TableCell
                className={
                  sortedField === 'itemsCount'
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortWarranties('itemsCount')}
              >
                Number of Items
                {sortedField === 'itemsCount' &&
                  (sortDirection === 'asc' ? (
                    <ArrowUpward color='action' className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward color='action' className={classes.sortIcon} />
                  ))}
              </TableCell>
              <TableCell
                align='left'
                className={
                  sortedField === 'updatedAt'
                    ? classes.sortedHeader
                    : classes.header
                }
                onClick={() => sortWarranties('updatedAt')}
              >
                Date Updated
                {sortedField === 'updatedAt' &&
                  (sortDirection === 'asc' ? (
                    <ArrowUpward color='action' className={classes.sortIcon} />
                  ) : (
                    <ArrowDownward color='action' className={classes.sortIcon} />
                  ))}
              </TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warranties && warranties.length > 0 ? (
              warranties.map((warranty, index) => (
                <React.Fragment key={warranty._id}>
                  <TableRow>
                    <TableCell
                      align='left'
                      className={
                        sortedField === 'createdAt'
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {format(new Date(warranty.createdAt), 'MMM dd, yyyy')}
                    </TableCell>
                    <TableCell
                      className={
                        sortedField === 'warrantyStatus'
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {warranty.warrantyStatus}
                    </TableCell>
                    <TableCell
                      className={
                        sortedField === 'itemsCount'
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {warranty.itemsCount}
                    </TableCell>
                    <TableCell
                      align='left'
                      className={
                        sortedField === 'updatedAt'
                          ? classes.sortedColumn
                          : classes.column
                      }
                    >
                      {format(new Date(warranty.updatedAt), 'MMM dd, yyyy')}
                    </TableCell>
                    <TableCell align='right'>
                    <WarrantyDialog mode='view' order={warranty.order} warranty={warranty} />
                </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  <h3>No warranties to display.</h3>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box display='flex'>
        </Box>
      </TableContainer>
      <TablePagination
        component="div"
        count={myWarranties.count? myWarranties.count: 0}
        page={page}
        rowsPerPage={20}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

export { WarrantyListAccount };
