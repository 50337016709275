import {
  BC_LIST_ITEMS_REQUEST,
  BC_LIST_ITEMS_SUCCESS,
  BC_LIST_ITEMS_FAIL,
  BC_LIST_PORTAL_ITEMS_REQUEST,
  BC_LIST_PORTAL_ITEMS_SUCCESS,
  BC_LIST_PORTAL_ITEMS_FAIL,
  BC_SALES_QUOTE_REQUEST,
  BC_SALES_QUOTE_SUCCESS,
  BC_SALES_QUOTE_FAIL,
  BC_SALES_QUOTE_RESET,
} from '../constants';

export const bcItemsListReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_LIST_ITEMS_REQUEST:
      return { loading: true };
    case BC_LIST_ITEMS_SUCCESS:




      const scrKits = action.payload.bcScreenKits
      const hardwareKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Housing')
      );

      const widthKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Width')
      );
      const meshKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Material')
      );
      const motorKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Motor')
      );
      const trackKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Track')
      );
      const barKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Bar')
      );
      const angleKits = scrKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Portal Kit' && attr.attributeValue === 'Angle')
      );
      const bcScreenKits = {
        hardwareKits,
        widthKits,
        meshKits,
        motorKits,
        trackKits,
        barKits,
        angleKits,
      };




      const rfKits = action.payload.bcRoofKits
      const frameKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Perimeter Frames')
      );
      const mBeamKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Midspan Beams')
      );
      const aBeamKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Accessory Beams')
      );
      const xBeamKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Extra Beams')
      );
      const bladeKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Blades')
      );
      const postKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Posts')
      );
      const addPartsKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Additional Parts')
      );
      const roofMotorKits = rfKits.filter(kit =>
        kit.attributes.some(attr => attr.attributeName === 'Roof Kit' && attr.attributeValue === 'Motor')
      );
      const bcRoofKits = {
        frameKits,
        mBeamKits,
        aBeamKits,
        xBeamKits,
        bladeKits,
        postKits,
        addPartsKits,
        roofMotorKits,
      };




      const { bcParts } = action.payload;
      return { loading: false, bcScreenKits, bcRoofKits, bcParts };
    case BC_LIST_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bcPortalItemsListReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_LIST_PORTAL_ITEMS_REQUEST:
      return { loading: true };
    case BC_LIST_PORTAL_ITEMS_SUCCESS:
      const {
        portalScreenKits,
        portalRoofKits,
        portalParts,
        portalCategories,
      } = action.payload;
      return {
        loading: false,
        portalScreenKits,
        portalRoofKits,
        portalParts,
        portalCategories,
      };
    case BC_LIST_PORTAL_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bcSalesQuoteInitReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_SALES_QUOTE_REQUEST:
      return { loading: true };
    case BC_SALES_QUOTE_SUCCESS:
      return { loading: false, salesQuote: action.payload };
    case BC_SALES_QUOTE_FAIL:
      return { loading: false, error: action.payload };
    case BC_SALES_QUOTE_RESET:
      return {};

    default:
      return state;
  }
};