// create warranty
export const WARRANTY_CREATE_REQUEST = 'WARRANTY_CREATE_REQUEST';
export const WARRANTY_CREATE_SUCCESS = 'WARRANTY_CREATE_SUCCESS';
export const WARRANTY_CREATE_FAIL = 'WARRANTY_CREATE_FAIL';

// warranty details
export const WARRANTY_DETAILS_REQUEST = 'WARRANTY_DETAILS_REQUEST';
export const WARRANTY_DETAILS_SUCCESS = 'WARRANTY_DETAILS_SUCCESS';
export const WARRANTY_DETAILS_FAIL = 'WARRANTY_DETAILS_FAIL';

// my warranty details
export const WARRANTY_LIST_MY_REQUEST = 'WARRANTY_LIST_MY_REQUEST';
export const WARRANTY_LIST_MY_SUCCESS = 'WARRANTY_LIST_MY_SUCCESS';
export const WARRANTY_LIST_MY_FAIL = 'WARRANTY_LIST_MY_FAIL';
export const WARRANTY_LIST_MY_RESET = 'WARRANTY_LIST_MY_RESET';

// warranty details
export const WARRANTY_DELETE_REQUEST = 'WARRANTY_DELETE_REQUEST';
export const WARRANTY_DELETE_SUCCESS = 'WARRANTY_DELETE_SUCCESS';
export const WARRANTY_DELETE_FAIL = 'WARRANTY_DELETE_FAIL';
export const WARRANTY_DELETE_RESET = 'WARRANTY_DELETE_RESET';
