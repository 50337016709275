import {
  Box,
  // Button,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Message,
  Loader,
  OrderListAdmin,
  OrderListAccount,
  WarrantyListAccount,
  WarrantyListAdmin
} from '.';

const WarrantyList = ({ type }) => {
  const history = useHistory();
  
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;


  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [history, userInfo]);

  let title = "My Warranties" ;
  if(type === 'admin'){
    title = "Dealer-Submitted Warranties"
  }

  // // console.log('orderlist shipped', shipped);

  return (
    <>
      <Box display='flex' style={{ alignItems: 'center' }}>
        <Typography variant='h6'>
          {title} 
        </Typography>

      </Box>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity='error'>{error}</Message>
      ) : type ==="admin" ? (
        <WarrantyListAdmin/>
      ) : (
        // ) : admin && shipped ? (
        //   <OrderListAdmin shipped={true} />
        <WarrantyListAccount/>
      )}
    </>
  );
};

export { WarrantyList };
